import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { RestoreScroll } from './restore-scroll'
import { SearchBar } from './search-bar'
import { SvgPlus } from './svg'
import { Menu } from './menu'
import SvgHorizontalLogo from '../generated/svg/horizontal-logo'
import { Nav } from './nav'
import classnames from 'classnames'
import { querySelectorWithShadow } from '../utils/query-selector-with-shadow'
import { Link } from './link'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.4s, transform 0.4s;
  z-index: 2;
  pointer-events: none;
  @media (max-width: 64rem){
    background-color: white; 
    pointer-events: fill;
  }
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5625rem var(--page-margin);
`

const Logo = styled(Link)`
  place-content: center;
  pointer-events: auto;
  display: block;
  transition: opacity 0.4s;
  &.hide {
    opacity: 0;
    @media (max-width: 40rem){
      opacity: 1;
    }
  }
  svg {
    display: block;
    height: 1.875rem;
    width: auto;
  }
`

const NavContainer = styled.div`
  background-color: white;
  padding: 0.5rem 2rem;
  border-radius: 100rem;
  margin: -0.5rem -2rem;
  pointer-events: auto;
  @media (max-width: 64rem){
    display: none;
  }
`

const MenuButton = styled.div`
  position: relative;
  width: 1.5625rem;
  height: 1.375rem;
  place-self: center;
  display: none;
  z-index: 101;
  cursor: pointer;
  padding: 0.5rem;
  margin: -0.5rem;
  pointer-events: auto;
  @media (max-width: 64rem){
    display: block;
  }
  div {
    height: 0.125rem;
    width: 1.5625rem;
    background-color: currentColor;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    transition: opacity 0.4s, transform 0.4s;
  }
  div:nth-child(1){
    transform: translateY(-0.5rem);
  }       
  div:nth-child(2){

  }       
  div:nth-child(3){
    transform: translateY(0.5rem);
  }
  &.open {
    div:nth-child(1){
      transform: rotate(-45deg);
    }       
    div:nth-child(2){
      opacity: 0;
    }       
    div:nth-child(3){
      transform: rotate(45deg);
    }
  }
`



type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [hide, setHide] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [hideLogo, setHideLogo] = useState(true)
  const outerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    let lastScrollY = window.scrollY
    const listener = () => {
      if(scrollY < headerHeight || scrollY < lastScrollY || scrollY < 1){
        setHide(false)
      } else {
        setHide(true)
      }
      lastScrollY = scrollY
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [headerHeight])
  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const listener = () => {
        const {height} = outer.getBoundingClientRect()
        document.body.style.setProperty('--header-height', `${height}px`)
        setHeaderHeight(height)
      }
      const observer = new ResizeObserver(listener)
      observer.observe(outer)
      listener()
      return () => {
        observer.disconnect()
      }
    }
  }, [])
  useEffect(() => {
    let topSlider: Element | null = null
      const listener = () => {
        if(!topSlider){
          topSlider = querySelectorWithShadow('#top-slider')
        }
        if(topSlider){
          const {bottom} = topSlider.getBoundingClientRect()
          setHideLogo(bottom > 0)
        } else if(location.pathname !== '/'){
          setHideLogo(false)
        }
      }
      listener()
      window.addEventListener('scroll', listener)
      window.addEventListener('resize', listener)
      return () => {
        window.removeEventListener('scroll', listener)
        window.removeEventListener('resize', listener)
      }
  }, [])
  return <>
    <Outer ref={outerRef}>
      <Inner>
        <Logo href="/" className={classNames({hide: hideLogo})}>
          <SvgHorizontalLogo />
        </Logo>
        <NavContainer>
          <Nav />
        </NavContainer>
        <MenuButton onClick={() => setMenuOpen(v => !v)} className={classnames({open: menuOpen})}>
          <div />
          <div />
          <div />
        </MenuButton>
        <Menu open={menuOpen} onClose={() => setMenuOpen(false)} />
      </Inner>
    </Outer>
  </>
}
