import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgHorizontalLogo from '../generated/svg/horizontal-logo'
import { useBreakpoint } from '../hooks/use-break-point'
import SvgVerticalLogo from '../generated/svg/vertical-logo'
import { pageMaxWidth } from '../styles/page-max-width'
import { SvgRightAngle } from './svg'
import { Link } from './link'

const Outer = styled.div`
  background-color: var(--brown);
  color: white;
  margin-top: 10rem;
`

const Inner = styled.div`
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  padding-top: 4.6875rem;
  padding-bottom: 5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  @media (max-width: 64rem){
    grid-template-columns: 1fr;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`

const LogoContainer = styled(Link)`
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    display: block;
    height: 3.75rem;
    width: auto;
    @media (max-width: 40rem){
      height: 5rem;
    }
  }
`

const NavContainer = styled.div`
font-size: 0.8125rem;
line-height: 2.6923076923076925;
margin: calc(-0.5lh + 0.5em);
justify-self: flex-end;
display: flex;
flex-direction: column;
align-items: flex-end;
@media (max-width: 64rem){
  display: none;
}
`

const Nav = styled.div`
display: flex;
`

const NavItem = styled(Link)`
padding: 0 1rem;
  margin: 0 0;
  position: relative;
  display: block;
  & + & {
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 1em;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background-color: currentColor;
    }
  }
`

const MobileNav1 = styled.div`
display: none;
@media (max-width: 64rem){
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}
`

const MobileNav1Item = styled(Link)`
display: block;
font-size: 0.875rem;
padding: 0.75rem 0;
display: flex;
align-items: center;
justify-content: space-between;
font-weight: 700;
@media (max-width: 40rem){
  padding: 0.7rem 0;
}
svg {
  display: block;
  height: 0.75rem;
  width: auto;
}
`

const MobileNav2 = styled.div`
display: none;
margin-top: 1.5rem;
flex-wrap: wrap;
justify-content: center;
@media (max-width: 64rem){
  display: flex;
}
`

const MobileNav2Item = styled(Link)`
display: block;
font-size: 0.75rem;
line-height: 2.0416666666666665;
padding: 0.25rem 1rem;
margin: -0.25rem 0;
`

const Copyright = styled.div`
font-size: 0.6875rem;
line-height: normal;
letter-spacing: 0.08em;
font-family: var(--en-font);
text-align: right;
grid-column: -2;
margin-top: 0.875rem;
@media (max-width: 64rem){
  text-align: center;
}
`

const Break = styled.div`
  height: 0;
  flex: 0 0 100%;
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  const isMobile = useBreakpoint('64rem')
  return <Outer>
    <Inner>
      <LogoContainer href="/">
        {
          isMobile ? <SvgVerticalLogo /> : <SvgHorizontalLogo />
        }
      </LogoContainer>
      <NavContainer>
        <Nav>
          <NavItem href="/Page/company.aspx">
            会社概要
          </NavItem>
          <NavItem href="/Page/terms.aspx">
            利用規約
          </NavItem>
          <NavItem href="/Page/privacy.aspx">
            個人情報保護方針
          </NavItem>
        </Nav>
        <Nav>
          <NavItem href="/Page/privacy2.aspx">
            個人情報の取り扱いについて
          </NavItem>
          <NavItem href="/Page/tokushoho.aspx">
            特定商取引に関する法律に基づく表記
          </NavItem>
        </Nav>
        <Nav>
          <NavItem href="/#about">
          「まめとくらす」とは？
          </NavItem>
          <NavItem href="/#products">
            商品について
          </NavItem>
          <NavItem href="/#howto">
            ご利用方法
          </NavItem>
          <NavItem href="/Page/faq.aspx">
            よくある質問
          </NavItem>
          <NavItem href="/Form/Inquiry/InquiryInput.aspx">
            お問い合わせ
          </NavItem>
        </Nav>
      </NavContainer>
      <MobileNav1>
        <MobileNav1Item href="/#about">
          「まめとくらす」とは？
          <SvgRightAngle size={8} />
        </MobileNav1Item>
        <MobileNav1Item href="/#products">
          商品について
          <SvgRightAngle size={8} />
        </MobileNav1Item>
        <MobileNav1Item href="/#howto">
          ご利用方法
          <SvgRightAngle size={8} />
        </MobileNav1Item>
        <MobileNav1Item href="/Page/faq.aspx">
          よくある質問
          <SvgRightAngle size={8} />
        </MobileNav1Item>
        <MobileNav1Item href="/Form/Inquiry/InquiryInput.aspx">
          お問い合わせ
          <SvgRightAngle size={8} />
        </MobileNav1Item>
      </MobileNav1>
      <MobileNav2>
        <MobileNav2Item href="/Page/company.aspx">
          会社概要
        </MobileNav2Item>
        <MobileNav2Item href="/Page/terms.aspx">
          利用規約
        </MobileNav2Item>
        <MobileNav2Item href="/Page/privacy.aspx">
          個人情報保護方針
        </MobileNav2Item>
        <MobileNav2Item href="/Page/privacy2.aspx">
          個人情報の取り扱いについて
        </MobileNav2Item>
        <Break />
        <MobileNav2Item href="/Page/tokushoho.aspx">
          特定商取引に関する法律に基づく表記
        </MobileNav2Item>
      </MobileNav2>
      <Copyright>
        @ 2024 MAME TO CLASS
      </Copyright>
    </Inner>
  </Outer>
}
