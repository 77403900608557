import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { SvgMinus, SvgPlus } from '../components/svg'
import classNames from 'classnames'
import { SectionHead } from '../components/section-head'
import { ll } from '../utils/lcqw'
import { pageMaxWidth } from '../styles/page-max-width'
import { Mbr } from '../components/br'
import { Animate } from '../components/animate'
import { count } from '../styles/count'
import { slideinFromRight } from '../styles/keyframes'
import { Link } from '../components/link'

const Outer = styled.div`
container-type: inline-size;
${pageMaxWidth}
`

const Inner = styled.div`

padding-bottom: ${ll(120)};
display: flex;
flex-direction: column;
align-items: center;
    @media (max-width: 40rem){
      padding-bottom: ${ll(70)};
    }
`

const List = styled.div`

width: ${ll(850)};
@media (max-width: 40rem){
  width: 100%;
}
`

const Text = styled.div`
font-size: ${ll(16)};
line-height: 1.75;
letter-spacing: 0.04em;
font-weight: 700;
text-align: center;
margin-top: ${ll(49)};
@media (max-width: 40rem){
  font-size: ${ll(14)};
line-height: 1.5;
letter-spacing: 0.04em;
font-weight: 700;
margin-top: ${ll(27)};
}
`

const Button = styled(Link)`
display: block;
font-size: ${ll(18)};
line-height: normal;
letter-spacing: 0.03em;
font-weight: 700;
margin-inline: auto;
width: fit-content;
padding: ${ll(16)} ${ll(58)};
background-color: var(--green);
color: white;
border-radius: 100rem;
margin-top: ${ll(22)};
transition: opacity 0.4s;
&:hover {
  opacity: 0.75;
}
@media (max-width: 40rem){
  font-size: ${ll(16)};
line-height: normal;
letter-spacing: 0.03em;
font-weight: 700;
padding: ${ll(14)} ${ll(55)};
}
`

const faqs: Faq[] = [
  {
    q: "「まめとくらす」はヴィーガン対応ですか？",
    a: "まめとくらすは全商品動物性原材料不使用であり、 NPO法人ベジプロジェクトジャパンが発行するヴィーガン認証マークを取得しております。 ヴィーガン認証基準については、以下サイトをご覧ください。\n<a href=\"https://vegeproject.org/certificate/\" target=\"_blank\">https://vegeproject.org/certificate/</a>\n\n※同じ製造設備で、動物由来原料を含む製品製造も行っております。"
  },
  {
    q: "アレルギーの人が食べても大丈夫ですか？",
    a: "まめとくらすは、動物性原材料不使用・小麦不使用ではございますが、アレルギーに完全対応はしておりません。 同じ製造設備で、さまざまなアレルゲンを含む製品製造も行っておりますので、 アレルギーによって重篤な症状が出る方は、ご利用をお控えください。 商品毎のアレルゲン情報については、パッケージをご確認ください。"
  },
  // {
  //   q: "遺伝子組換えの原料は使用していませんか？",
  //   a: "まめとくらすは、遺伝子組換え混入防止管理済の原料を使用しております。 ただし、遺伝子組換え表示義務がない原材料、ラベル表示義務のない加工助剤やキャリーオーバー添加物については、 一部、不分別の原料を使用していることがあります。 \n\n※「遺伝子組換え混入防止管理済」とは 遺伝子組換え農産物の混入を防ぐため、遺伝子組換え農産物と非遺伝子組換え農産物を、 生産・流通・加工の各段階で混入が起こらないよう管理し、そのことが書類などにより証明されていることです。"
  // },
  // {
  //   q: "電子レンジ又は湯煎で温めることはできますか？",
  //   a: "「まめとくらす」は電子レンジ対応パウチのレトルト食品です。 電子レンジの加熱でも湯せんでも、どちらも美味しく召し上がれます。電子レンジご使用時は、袋のまま加熱できるので、 お皿もレンジも汚さず、カンタン調理できます。\n\n ※加熱時間につきましては、商品パッケージ（外側）をご確認ください。"
  // },
  {
    q: "定期便の送料はいくらですか？",
    a: "定期便は、ご購入金額にかかわらず送料無料です。"
  },
  // {
  //   q: "定期便のスキップや解約はできますか？",
  //   a: "いつでもスキップや解約が可能です。 定期便のお届けを止める方法は「停止」と「解約」の２種類あります。 \n\n＜スキップ＞　 \n次回お届け予定日の3日前までにご対応ください。\n [対応方法] マイページの定期購入情報詳細より、+２週間後 または +３週間後 を選択し「次回配送スキップ」を押します。 変更後の次回お届け予定日をご確認ください。 \n\n＜停止＞ お電話にて承ります。 \nまめとくらすカスタマーセンターまでご連絡ください。 \n\n＜解約＞ お電話にて承ります。 \nまめとくらすカスタマーセンターまでご連絡ください。 \n\n▼まめとくらすカスタマーセンター 　\n050-1744-0000（10:00～17:00（土日祝・年末年始を除く）） \n※初回ご注文分の配送日の変更、及び、初回ご注文分配送前の解約はできません。"
  // },
  // {
  //   q: "定期便のお届け日を変更することはできますか？",
  //   a: "可能です。 次回お届け予定日の9日前までに、マイページよりお手続きください。"
  // },
  {
    q: "商品が届くまで何日かかりますか？",
    a: "ご注文確認後、2営業日以内に発送いたします。 発送後、2～3日でお届けします。"
  },
  {
    q: "どのような支払い方法が利用できますか？",
    a: "クレジットカード決済・AmazonPay・後払い決済（GMO後払い）をご利用いただけます。"
  },
  // {
  //   q: "注文後、支払い方法の変更はできますか？",
  //   a: "初回のご注文確定後、お支払い方法の変更はできません。ご了承ください。 ※ただし2回目以降はマイページよりお支払方法の変更が可能です。 "
  // }
]

type TopFaqSectionProps = {
  hideContact?: boolean
}
export const TopFaqSection: FunctionComponent<TopFaqSectionProps> = ({hideContact}) => {
  const faq: Faq[] = JSON.parse(document.getElementById('faq-data')?.textContent ?? 'null') ?? faqs
  return <Outer id="faq">
    <Inner>
      <SectionHead label="FAQ">
        よくある質問
      </SectionHead>
      <Animate>
        <List>
          {faq.map((faq, i) => {
            return <FaqItem key={i} data={faq} />
          })}
        </List>
      </Animate>
      {
        hideContact || <>
          <Text>
            ご不明点などございましたら、<br />
            お問い合わせフォームより<Mbr />お気軽にご相談ください。
          </Text>
          <Button href="/Form/Inquiry/InquiryInput.aspx">
            お問い合わせはこちら
          </Button> 
        </>
      }
    </Inner>
  </Outer>
}

const Item = styled.div`
background: var(--beige);
position: relative;
display: grid;
& + & {
  margin-top: ${ll(13)};
  @media (max-width: 40rem){
  margin-top: ${ll(10)};
  }
}
${count}
  opacity: 0;
.animate & {
  animation: ${slideinFromRight} 1s calc(0.2s * var(--count)) both;
}
`

const IconContainer = styled.div`
position: absolute;
left: ${ll(29)};
top: ${ll(30)};
@media (max-width: 40rem){
left: ${ll(20)};
top: ${ll(19)};
  
}
svg {
  display: block;
  height: ${ll(21)};
  width: auto;
  @media (max-width: 40rem){
    height: ${ll(15)};
  }
}
`

const QRow = styled.div`
  cursor: pointer;
padding: ${ll(23)} ${ll(106)};
@media (max-width: 40rem){
padding: ${ll(11)} ${ll(51)};
  
}
`

const QText = styled.div`
font-size: ${ll(15)};
line-height: 1.75;
letter-spacing: 0.04em;
font-weight: 700;
white-space: pre-wrap;
&::before {
  content: 'Q.';
}
`

const ARow = styled.div`

display: grid;
grid-template-rows: 0fr;
transition: grid-template-rows 0.4s;
${Item}.open & {
  grid-template-rows: 1fr;
}
`

const AText = styled.div`
font-size: ${ll(15)};
line-height: 1.75;
letter-spacing: 0.04em;
font-weight: 500;
padding-top: ${ll(16)};
overflow: hidden;
padding: 0 ${ll(106)};

box-sizing: border-box;
transition: padding-bottom 0.4s;
white-space: pre-wrap;
@media (max-width: 40rem){
padding: 0 ${ll(51)};
}
${Item}.open & {
  padding-bottom: ${ll(23)};
  @media (max-width: 40rem){
    padding-bottom: ${ll(11)};
  }
}
&::before {
  content: 'A.';
}
a {
  text-decoration: underline;
}
`

type Faq = {
  q: string,
  a: string
}

type FaqItemProps = {
  data: Faq
}
const FaqItem: FunctionComponent<FaqItemProps> = ({data}) => {
  const [open, setOpen] = useState(false)
  return <Item className={classNames({open})}>
    <QRow onClick={() => {setOpen(v => !v)}}>
      <IconContainer>
        {
          open ? <SvgMinus size={8} /> : <SvgPlus size={8} />
        }
      </IconContainer>
      <QText dangerouslySetInnerHTML={{__html: data.q}} />
    </QRow>
    <ARow>
      <AText dangerouslySetInnerHTML={{__html: data.a}} />
    </ARow>
  </Item>
}
